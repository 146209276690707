import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Debt Issuance Program"/>
            <Banner title="Debt Issuance Program"/>
            <section className="service_details_area aspectos servicios">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">This solutions package is aimed at companies that have the capacity to issue
                            debt (even a registered Eurobond), and are willing to enter the debt markets, through a new
                            issuance. IRStrat’s solution focuses on easing the issuance process, shortening the
                            execution time, and minimizing the issuance cost. This package includes:
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="service_left_sidebar">
                                <ul className="nav nav-tabs service_tab mb_50" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="paquete1-tab" data-toggle="tab"
                                           href="#paquete1" role="tab"
                                           aria-controls="paquete1" aria-selected="true">Package 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete2-tab" data-toggle="tab" href="#paquete2"
                                           role="tab"
                                           aria-controls="paquete2" aria-selected="false">Package 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete3-tab" data-toggle="tab" href="#paquete3"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Package 3</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="paquete1" role="tabpanel"
                                     aria-labelledby="paquete1-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_c`ontent">
                                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#accordion"
                                                               href="#collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic plan
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse1" className="collapse show" aria-expanded="true"
                                                         role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Identification
                                                                    of markets and interest rates
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Prospecting of
                                                                    debt cost
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Initial study
                                                                    on issuance debt and reach
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Development of
                                                                    capacity assessment grid
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Determination
                                                                    of the go-to-market calendar
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Prospectus
                                                                    filing agenda
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic core
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Investment
                                                                    thesis development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Shaping of
                                                                    competitive advantage narrative
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Crafting of the
                                                                    history narrative
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Communication
                                                                    style shaping (qualitative and quantitative mix)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Communication
                                                                    strategy
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic prospecting
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Underwriter
                                                                    syndicate targeting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    beauty contest of underwriter syndicate
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Institutional
                                                                    investors targeting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Closed book
                                                                    proxy
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    road show
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preparation of
                                                                    briefing books on investors
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Lobbying
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    to pension and investment funds
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    to wealth management areas
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    to credit rating agencies
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Approach to
                                                                    specialized media
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete2" role="tabpanel"
                                     aria-labelledby="paquete2-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p2_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p2_accordion"
                                                               href="#p2_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p2_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>C-Suite communications
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Market
                                                                    communication training for senior management
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preparation and
                                                                    consultancy on 1x1 meetings and road show
                                                                    presentations
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Crisis
                                                                    management consultancy
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p2_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Structuring of policies and
                                                                procedures
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Disclosure
                                                                    policy development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Core functions
                                                                    mapping
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>IR flowchart
                                                                    creation (functions and methodology)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Regulatory compliance</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with CNBV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with CONSAR
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with BMV
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with BIVA
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Maximum
                                                                    compliance with SEC
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Analyst coverage</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Valuation
                                                                    models development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Sell-side
                                                                    investors targeting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    to fixed-income analysts
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete3" role="tabpanel"
                                     aria-labelledby="paquete3-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p3_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p3_accordion"
                                                               href="#p3_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p3_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Benchmarking
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Identification
                                                                    of industry and peers
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    valuations
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    results
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    go-to-market
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p3_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Development of documents,
                                                                reports and materials
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Prospectus’
                                                                    drafting, structuring and circle up
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Investor
                                                                    presentation drafting, structuring, design and
                                                                    circle-up
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Investment fact
                                                                    sheet drafting, structuring, design and circle-up
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>IR website</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Structuring and
                                                                    graphical development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Content
                                                                    drafting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Optimized
                                                                    platform for mobile devices
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Interactive
                                                                    tools (valuation multiples, key performance
                                                                    indicators and stock information)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>IR Edge 3.0 services
                                                                </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>CRM tool for
                                                                    investors
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Web Analytics
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Dedicated
                                                                    servers in the cloud
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Translation
                                                                    services (Spanish-English)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Intelligence
                                                                    interface
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
